<template>
	<div>
		<!-- Select Actions and Items Per Page Options -->
		<b-row>
			<b-col sm="6" md="6">
				<span class="details-view-title">CHANGE LOGS</span>
				<div class="mb-2 details-view-subtitle">Recent update logs created by {{ isSuperAdmin ? 'all users' :
					loggedUser.id }}</div>
			</b-col>
			<b-col sm="6" md="4" offset-md="2" class="mb-2 text-md-right">
				<b-input-group prepend="Show" append="/ Page">
					<b-form-select :options="pageOptions" v-model="perPage" />
				</b-input-group>
			</b-col>
		</b-row>

		<b-table show-empty striped hover :items="changeLogs" :fields="fields" :current-page="currentPage"
			:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
			:sort-direction="sortDirection" responsive>
			<template v-slot:cell(dateUpdated)="row">
				<div>
					{{ getFormattedDateWithTime(row.item.new.dateUpdated) }}
				</div>
			</template>

			<template v-slot:cell(changes)="row">

				<div class="changed-section" v-if="hasValueChanged(row.item, 'firstName')">
					<b>First Name</b>
					<br>
					From <b class="old-value"> {{ getOldValue(row.item, 'firstName') }} </b>
					to <b class="new-value"> {{ getNewValue(row.item, 'firstName') }} </b>
				</div>
				<div class="changed-section" v-if="hasValueChanged(row.item, 'middleName')">
					<b>Middle Name</b>
					<br>
					From <b class="old-value"> {{ getOldValue(row.item, 'middleName') }} </b>
					to <b class="new-value"> {{ getNewValue(row.item, 'middleName') }} </b>
				</div>
				<div class="changed-section" v-if="hasValueChanged(row.item, 'lastName')">
					<b>Last Name</b>
					<br>
					From <b class="old-value"> {{ getOldValue(row.item, 'lastName') }} </b>
					to <b class="new-value"> {{ getNewValue(row.item, 'lastName') }} </b>
				</div>

				<div class="changed-section" v-if="hasValueChanged(row.item, 'company')">
					<b>Company</b>
					<br>
					From <b class="old-value"> {{ getOldValue(row.item, 'company') }} </b>
					to <b class="new-value"> {{ getNewValue(row.item, 'company') }} </b>
				</div>

				<div class="changed-section" v-if="hasValueChanged(row.item, 'position')">
					<b>Position</b>
					<br>
					From <b class="old-value"> {{ getOldValue(row.item, 'position') }} </b>
					to <b class="new-value"> {{ getNewValue(row.item, 'position') }} </b>
				</div>

				<div class="changed-section" v-if="hasValueChanged(row.item, 'employeeNo')">
					<b>Employee No.</b>
					<br>
					From <b class="old-value numFont"> {{ getOldValue(row.item, 'employeeNo') }} </b>
					to <b class="new-value numFont"> {{ getNewValue(row.item, 'employeeNo') }} </b>
				</div>

				<div class="changed-section" v-if="hasValueChanged(row.item, 'type')">
					<b>Access Type</b>
					<br>
					From <b class="old-value"> {{ getOldValue(row.item, 'type') }} </b>
					to <b class="new-value"> {{ getNewValue(row.item, 'type') }} </b>
				</div>

				<div class="changed-section" v-if="hasValueChanged(row.item, 'contactNo')">
					<b>Contact No.</b>
					<br>
					From <b class="old-value numFont"> {{ getOldValue(row.item, 'contactNo') }} </b>
					to <b class="new-value numFont"> {{ getNewValue(row.item, 'contactNo') }} </b>
				</div>

				<div class="changed-section" v-if="hasValueChanged(row.item, 'isActive')">
					<b>Status</b>
					<br>
					From <span v-if="row.item.old !== null">
						<UserStatus :user="row.item.old" />
					</span>
					<span v-else>&nbsp;-&nbsp;</span>
					to
					<UserStatus :user="row.item.new" />
				</div>

				<div class="changed-section" v-if="hasValueChanged(row.item, 'has2WayAuth')">
					<b>Enable 2-Way Auth?</b>
					<br>
					From <b class="old-value">
						{{ getOldValue(row.item, 'has2WayAuth') === true ? 'YES' : 'NO' }}
					</b>
					to <b class="new-value">
						{{ getNewValue(row.item, 'has2WayAuth') === true ? 'YES' : 'NO' }}
					</b>
				</div>

				<div class="changed-section" v-if="hasValueChanged(row.item, 'hasNotif')">
					<b>Has Notification?</b>
					<br>
					From <b class="old-value">
						{{ getOldValue(row.item, 'hasNotif') === true ? 'YES' : 'NO' }}
					</b>
					to <b class="new-value">
						{{ getNewValue(row.item, 'hasNotif') === true ? 'YES' : 'NO' }}
					</b>
				</div>

			</template>

			<template v-slot:cell(updatedBy)="row">{{ row.item.new.updatedBy }}</template>
		</b-table>
		<b-row>
			<b-col md="8" sm="12" class="my-1">
				<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
			</b-col>
			<b-col md="4" sm="12" class="my-1">
				<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
					class="my-0" />
			</b-col>
		</b-row>

		<b-row class="mt-4 mb-2">
			<b-col sm="12" class="text-sm-right">
				<b-button size="sm" @click="row.toggleDetails" variant="secondary"
					v-b-tooltip.hover.top="'Hide Details'">
					<i class="icon-arrow-up"></i>
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>

<script>
// API & Database
import auditTrailLogsDAO from '@/database/auditTrailLogs';

// Components
import UserStatus from '@/views/setup/user/UserStatus';

// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import _ from 'lodash';

export default {
	name: 'user-change-logs-details-view',
	components: {
		UserStatus
	},
	props: {
		row: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			changeLogs: [],
			fields: [
				'dateUpdated',
				'changes',
				'updatedBy',
			],
			currentPage: 1,
			perPage: 5,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			userId: '',

			loggedUser: this.$store.getters.loggedUser,
			isSuperAdmin: this.$store.getters.isSuperAdmin,
		};
	},
	watch: {
		userId: async function (newVal) {
			if (newVal && newVal.length > 0) {
				await this.retrieveChangeLog(newVal);
			}
		}
	},
	async mounted() {
		this.userId = this.row.item.id;
		await this.retrieveChangeLog(this.userId);
	},
	methods: {
		async retrieveChangeLog(userId) {
			if (userId) {
				let param = {
					collection: 'users',
					id: userId,
					userId: !this.isSuperAdmin ? this.loggedUser.id : ''
				};

				let results = await auditTrailLogsDAO.getAuditTrailLogs(param);
				this.changeLogs = Object.values(results);

				this.filterChangeLogs(this.changeLogs);
				this.totalRows = _.size(this.changeLogs);
			}
		},
		filterChangeLogs(changeLogs) {
			const filteredLogs = _.filter(changeLogs, (log) => {
				const oldLog = log.old ? log.old : {};
				const newLog = log.new ? log.new : {};

				// First Name
				const oldFirstName = oldLog.firstName ? oldLog.firstName : '';
				const newFirstName = newLog.firstName ? newLog.firstName : '';

				// Middle Name
				const oldMiddleName = oldLog.middleName ? oldLog.middleName : '';
				const newMiddleName = newLog.middleName ? newLog.middleName : '';

				// Last Name
				const oldLastName = oldLog.lastName ? oldLog.lastName : '';
				const newLastName = newLog.lastName ? newLog.lastName : '';

				// Company
				const oldCompany = oldLog.company ? oldLog.company : '';
				const newCompany = newLog.company ? newLog.company : '';

				// Position
				const oldPosition = oldLog.position ? oldLog.position : '';
				const newPosition = newLog.position ? newLog.position : '';

				// Employee No
				const oldEmployeeNo = oldLog.employeeNo ? oldLog.employeeNo : '';
				const newEmployeeNo = newLog.employeeNo ? newLog.employeeNo : '';

				// Access Type
				const oldAccessType = oldLog.type ? oldLog.type : '';
				const newAccessType = newLog.type ? newLog.type : '';

				// Contact No
				const oldContactNo = oldLog.contactNo ? oldLog.contactNo : '';
				const newContactNo = newLog.contactNo ? newLog.contactNo : '';

				// Status
				const oldStatus = oldLog.isActive ? oldLog.isActive : '';
				const newStatus = newLog.isActive ? newLog.isActive : '';

				// Has2WayAuth
				const oldHas2WayAuth = oldLog.has2WayAuth ? oldLog.has2WayAuth : '';
				const newHas2WayAuth = newLog.has2WayAuth ? newLog.has2WayAuth : '';

				// HasNotif
				const oldHasNotif = oldLog ? oldLog.hasNotif : '';
				const newHasNotif = newLog ? newLog.hasNotif : '';

				return oldFirstName !== newFirstName ||
					oldMiddleName !== newMiddleName ||
					oldLastName !== newLastName ||
					oldCompany !== newCompany ||
					oldPosition !== newPosition ||
					oldEmployeeNo !== newEmployeeNo ||
					oldAccessType !== newAccessType ||
					oldContactNo !== newContactNo ||
					oldStatus !== newStatus ||
					oldHas2WayAuth !== newHas2WayAuth ||
					oldHasNotif !== newHasNotif;
			});
			this.changeLogs = filteredLogs;
		},

		hasValueChanged(log, fieldName) {
			let oldLog = log.old ? log.old : {};
			let newLog = log.new ? log.new : {};

			let oldValue = oldLog[fieldName] ? oldLog[fieldName] : "-";
			let newValue = newLog[fieldName] ? newLog[fieldName] : "-";
			return oldValue !== newValue;
		},

		// UTILS
		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
		getOldValue(log, fieldName) {
			let oldLog = log.old ? log.old : {};
			let value = oldLog[fieldName] ? oldLog[fieldName] : "-";
			return value;
		},
		getNewValue(log, fieldName) {
			let newLog = log.new ? log.new : {};
			let value = newLog[fieldName] ? newLog[fieldName] : "-";
			return value;
		},
	},
}
</script>

<style scoped>
.new-value {
	color: green;
}

.old-value {
	color: red;
}

.changed-section {
	margin-bottom: 10px;
}
</style>
