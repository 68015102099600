<template>
	<div class="selected-table">
		<b-row>
			<b-col sm="6" class="mb-1">
				<i class="icon-check"></i>&nbsp;
				<b>SELECTED</b>
			</b-col>
			<b-col lg="6" md="12" sm="12" align="right">
				<b-button variant="primary" v-b-tooltip.hover.top="'Import from Templates'"
					v-b-modal.import-company-access class="mr-1" size="sm">
					Import Template
				</b-button>
			</b-col>
		</b-row>
		<b-row class="my-2">
			<b-col sm="6">
				<span class="total-display">
					Selected: {{ Object.keys(selected).length }}
				</span>
			</b-col>
			<b-col sm="6" class="text-sm-right">
				<b-button variant="danger" @click="deleteSelectedCompanies"
					v-b-tooltip.hover.top="'Delete Selected Companies'" class="mr-1">
					<i class="fa fa-trash"></i>
				</b-button>
			</b-col>
		</b-row>
		<b-row class="my-2">
			<b-col sm="6">
				<b-button size="sm" @click="selectAllRows">Select all</b-button>&nbsp;
				<b-button size="sm" @click="clearSelected">Clear</b-button>&nbsp;
			</b-col>
			<b-col sm="6" class="text-sm-right">
				<b-input-group prepend="Show" append="/ Page" size="sm">
					<b-form-select :options="pageOptions" v-model="perPage" />
				</b-input-group>
			</b-col>
		</b-row>

		<b-row class="mx-1">
			<b-table ref="selectedCompaniesTable" show-empty striped hover :items="items" :fields="fields"
				:current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
				:sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive selectable select-mode="multi"
				@row-selected="onRowSelected" selected-variant="primary">
				<template v-slot:cell(selected)="{ rowSelected }">
					<template v-if="rowSelected">
						<span aria-hidden="true">&check;</span>
						<span class="sr-only">Selected</span>
					</template>
					<template v-else>
						<span aria-hidden="true">&nbsp;</span>
						<span class="sr-only">Not selected</span>
					</template>
				</template>
			</b-table>
		</b-row>
		<b-row>
			<b-col sm="6">
				<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
			</b-col>
			<b-col sm="6">
				<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
					size="sm" />
			</b-col>
		</b-row>

		<ImportCompanyAccess @onSelCompanyAccessTemplate="importCompanyAccess" />
	</div>
</template>

<script>
// Components
import ImportCompanyAccess from './ImportCompanyAccess';

// Others
import EventBus from '@/shared/event-bus';
import _ from 'lodash';

export default {
	name: 'selected-companies-table',
	components: {
		ImportCompanyAccess,
	},
	props: {
		user: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'selected',
					label: '#',
					sortable: false,
				},
				{
					key: 'name',
					label: 'Name',
					sortable: true,
				},
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			allCompaniesOptions: {},

			// for selected fields
			selected: {},
		};
	},
	mounted() {
		EventBus.$on('addSelectedCompanies', (selCompaniesArr) => {
			this.addSelectedCompanies(selCompaniesArr);
		});
	},
	methods: {
		importCompanyAccess(template) {
			this.addSelectedCompanies(template.companyAccess);
		},
		onRowSelected(items) {
			this.selected = items;
		},
		selectAllRows() {
			this.$refs.selectedCompaniesTable.selectAllRows();
		},
		clearSelected() {
			this.$refs.selectedCompaniesTable.clearSelected();
		},
		containsCompany(obj, list) {
			for (const element of list) {
				if (element['name'] === obj['name']) {
					return true;
				}
			}
			return false;
		},
		addSelectedCompanies(selCompaniesArr) {
			let newCompanies = selCompaniesArr;
			newCompanies.forEach((company) => {
				// not yet part of selected companies
				if (!this.containsCompany(company, this.items)) {
					this.items.push(company);
					// have a backup object that will contain all objects
					this.allCompaniesOptions[company.name] = company;
				}
			});
			this.totalRows = this.items.length;
			this.$emit('onAddSelCompanies', selCompaniesArr);
		},
		deleteSelectedCompanies() {
			if (_.isEmpty(this.selected)) {
				this.$toaster.warning(
					'Please select the companies you want to remove from selection.'
				);
				return;
			}

			let originCompany = _.filter(this.selected, (item) => {
				return this.user && this.user.companyId === item.id;
			});
			if (!_.isEmpty(originCompany)) {
				this.$toaster.warning(
					"You can't delete default company access " +
					this.user.company +
					' of this user.'
				);
				return;
			}

			let tickedRows = Object.values(this.selected);
			tickedRows.forEach((tickedRow) => {
				let name = tickedRow.name;
				EventBus.$emit('onDeleteCompany', this.allCompaniesOptions[name]);
				delete this.allCompaniesOptions[name];
			});

			// emit event to update the companies on the assign company access dialog
			let deletedCompanyNamesArr = _.map(tickedRows, 'name');
			this.$emit('onDeleteSelCompanies', deletedCompanyNamesArr);

			this.items = Object.values(this.allCompaniesOptions);
			this.totalRows = this.items.length;
		},
		beforeDestroy() {
			EventBus.$off('addSelectedCompanies');
		},
	},
};
</script>

<style scoped>
.selected-table {
	margin-top: 53px !important;
}
</style>
