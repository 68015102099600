import { db } from '@/config/firebase';


async function getAllCompanyAccessTemplates() {
    let templatesObj = {};

    const dbRef = db.collection('companyAccessTemplates');
    const querySnapshots = await dbRef.get();

    querySnapshots.forEach(doc => {
        const id = doc.id;
        let templateObj = doc.data();
        templatesObj[id] = {
            id: id,
            ...templateObj
        }
    });

    return templatesObj;
}

async function getCompanyAccessTemplates(companyId) {
    let templatesObj = {};

    const dbRef = db.collection('companyAccessTemplates').where("companyId", "==", companyId);
    const querySnapshots = await dbRef.get();

    querySnapshots.forEach(doc => {
        const id = doc.id;
        let templateObj = doc.data();
        templatesObj[id] = {
            id: id,
            ...templateObj
        }
    });

    return templatesObj;
}

async function saveCompanyAccessTemplate(templateObj) {
    let query;
    if (templateObj.id) {
        query = db.collection('companyAccessTemplates').doc(templateObj.id);
    } else {
        query = db.collection('companyAccessTemplates').doc();
        templateObj.id = query.id;
    }

    return query.set(templateObj);
}

export default {
    getAllCompanyAccessTemplates,
    getCompanyAccessTemplates,
    saveCompanyAccessTemplate,
}